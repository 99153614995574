import { Link } from 'gatsby';
import React, { useState } from 'react';
import { useTranslation } from '@/src/contexts/translation.context';
import * as styles from './navbar.module.scss';
import BookTicketIcon from '@/assets/icons/navbar/book_ticket.inline.svg';
import ActivitesIcon from '@/assets/icons/navbar/activities.inline.svg';
import MyPassesIcon from '@/assets/icons/navbar/my_passes.inline.svg';
import MyProfileIcon from '@/assets/icons/navbar/my_profile.inline.svg';
import { useEffect } from 'react';

export default function Navbar() {
  const translation = useTranslation();
  const [href, setHref] = useState('');

  useEffect(() => {
    setHref(window.location.href);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <Link to={`/${translation.lang}/book-ticket`} data-page="book-ticket" className={href.indexOf('book-ticket') > -1 ? styles['active'] : ''}>
          <BookTicketIcon></BookTicketIcon>
          {translation.translate('navbar.book_ticket')}
        </Link>
        <Link to={`/${translation.lang}/activities`} data-page="activities" className={href.indexOf('activities') > -1 ? styles['active'] : ''}>
          <ActivitesIcon></ActivitesIcon>
          {translation.translate('navbar.activities')}
        </Link>
        <Link to={`/${translation.lang}/my-passes`} data-page="my-passes" className={href.indexOf('my-passes') > -1 ? styles['active'] : ''}>
          <MyPassesIcon></MyPassesIcon>
          {translation.translate('navbar.my_passes')}
        </Link>
        <Link to={`/${translation.lang}/profile`} data-page="profile" className={href.indexOf('profile') > -1 ? styles['active'] : ''}>
          <MyProfileIcon></MyProfileIcon>
          {translation.translate('navbar.my_profile')}
        </Link>
      </div>
    </div>
  );
}