import Footer from '@/src/components/footer/footer';
import Header from '@/src/components/header/header';
import Navbar from '@/src/components/navbar/navbar';
import { Subheader } from '@/src/components/subheader/subheader';
import { TranslationProvider } from '@/src/contexts/translation.context';
import { TranslationService } from '@/src/services/translation.service';
import { navigate, PageProps } from 'gatsby';
import React, { forwardRef } from 'react';
import { useState } from 'react';
import IconInput from '@/src/components/icon-input/icon-input';
import DatePicker from 'react-datepicker';
import CalendarIcon from '@/assets/icons/input-icons/calendar.inline.svg';
import { createFormField, FormConfig, requiredValidator, travelerValidator } from '@/src/models/form.model';
import { useForm } from '@/src/hooks/form.hook';
import { FormError } from '@/src/components/form-error/form-error';
import { DurationSelect } from './duration-select';
import { AmountInput } from '@/src/components/amount-input/amount-input';
import * as styles from './cart.module.scss';
import { LargeButton } from '@/src/components/button/button';
import { CartModel } from '@/src/models/cart.model';
import { getProductService } from '@/src/services/product.service';
import { useEffect } from 'react';
import useAuthStatus from '@/src/hooks/auth-status.hook';
import useAuthGuard from '@/src/hooks/auth-guard.hook';
import { Helmet } from 'react-helmet';

const DatepickerIconInput = forwardRef(
  ({ value, onClick, onChange }: any, ref) => {
    return (
      <IconInput
        placeholder=""
        ref={ref}
        onClick={onClick}
        value={value}
        onChange={onChange}
        icon={<div><CalendarIcon style={{ width: '1rem', height: '1rem' }}></CalendarIcon></div>}></IconInput>
    );
  }
);

const cartForm: FormConfig = {
  startDate: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <DatePicker
            key={key}
            name="startDate"
            selected={value}
            value={value}
            onChange={(date) => onInputChange({ target: { name: 'startDate', value: date } } as any)}
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            customInput={<DatepickerIconInput></DatepickerIconInput>}>
          </DatePicker>
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    defaultValue: new Date(),
    validationRules: [
      requiredValidator()
    ]
  },
  passDuration: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <DurationSelect
            key={key}
            value={value}
            onChange={(value: any) => onInputChange({ target: { name: 'passDuration', value: value } } as any)}
            options={[{ name: '24h', value: 1 }, { name: '48h', value: 2 }, { name: '72h', value: 3 }]}></DurationSelect>
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  travelerCount: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <div className={styles.amountInput__wrapper}>
            <AmountInput
              key={key + '-1'}
              label={translation.translate('purchase.cart.adults')}
              value={value.travelerCountAdults || 0}
              onChange={(v) => onInputChange({ target: { name: 'travelerCount', value: { ...value, travelerCountAdults: v } } } as any)}></AmountInput>
          </div>
          <div className={styles.amountInput__wrapper}>
            <AmountInput
              key={key + '-2'}
              label={translation.translate('purchase.cart.young_adults')}
              value={value.travelerCountYoungAdults || 0}
              onChange={(v) => onInputChange({ target: { name: 'travelerCount', value: { ...value, travelerCountYoungAdults: v } } } as any)}></AmountInput>
          </div>
          <div className={styles.amountInput__wrapper}>
            <AmountInput
              key={key + '-3'}
              label={translation.translate('purchase.cart.children')}
              value={value.travelerCountChildren || 0}
              onChange={(v) => onInputChange({ target: { name: 'travelerCount', value: { ...value, travelerCountChildren: v } } } as any)}></AmountInput>
          </div>
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    defaultValue: { travelerCountAdults: 0, travelerCountChildren: 0, travelerCountYoungAdults: 0 },
    validationRules: [
      requiredValidator(),
      travelerValidator()
    ]
  }
};

export default function CartPage({ pageContext }: PageProps) {
  const [translation] = useState(new TranslationService(pageContext));
  const { renderInput, isFormValid, touchForm, form } = useForm(translation, cartForm);
  const [price, setPrice] = useState(0);
  useAuthGuard(`/${translation.lang}/login`);

  useEffect(() => {
    (async () => {
      const productService = getProductService();
      const priceModel = await productService.getPrice('bus-and-ferry');
      const duration = form.passDuration.value || 0;
      const numAdults = form.travelerCount.value.travelerCountAdults || 0;
      const numYoungAdults = form.travelerCount.value.travelerCountYoungAdults || 0;

      let price: number;

      if(duration === 3) {
        price = (numYoungAdults * priceModel.childExtended) + (numAdults * priceModel.adultExtended);
      } else {
        price = (numYoungAdults * priceModel.child * duration) + (numAdults * priceModel.adult * duration);
      }

      setPrice(price);
    })();
  }, [form]);

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    touchForm();
    if(!isFormValid()) {
      return;
    }
    navigate(
      `/${translation.lang}/purchase/checkout`,
      {
        state: {
          startDate: form.startDate.value,
          passDuration: form.passDuration.value,
          travelerCount: form.travelerCount.value
        } as CartModel
      }
    );
  };

  return (
    <TranslationProvider value={translation}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{translation.translate('helmet.purchase_title')}</title>
        <meta name="description" content={translation.translate('helmet.purchase_description')} />
        <meta http-equiv="content-language" content={translation.lang} />
      </Helmet>
      <Header></Header>
      <div className={`scrollable ${styles.scrollable}`}>
        <div className="content">
          <Subheader.Stepper firstStep={translation.translate('purchase.cart.choose_pass')} secondStep={translation.translate('purchase.checkout.confirm_and_pay')} activeStep={1}></Subheader.Stepper>
          <div className={styles.wrapper + " app__wrapper"}>
            <h1 className={styles.heading}>{translation.translate('purchase.cart.heading')}</h1>
            <p className={styles.text} dangerouslySetInnerHTML={{ __html: translation.translate('purchase.cart.text') }}></p>
            <form className="app__form" onSubmit={onSubmit}>

              <div className="form__group">
                <div className="form__field">
                  <label htmlFor="startDate">{translation.translate('purchase.cart.start_date_label')}</label>
                  {renderInput('startDate')}
                </div>
              </div>

              <div className="form__group">
                <div className="form__field">
                  <label htmlFor="duration">{translation.translate('purchase.cart.pass_duration_label')}</label>
                  {renderInput('passDuration')}
                </div>
              </div>

              <div className="form__group">
                <div className="form__field">
                  <label htmlFor="travelerCount">{translation.translate('purchase.cart.number_of_travelers_label')}</label>
                  {renderInput('travelerCount')}
                </div>
              </div>

              <div className={styles.divider}></div>

              <div className={styles.total}>
                <p className={styles.total__heading}>{translation.translate('purchase.cart.total')}</p>
                <p className={styles.total__value}>{price}€</p>
              </div>

              <div className={styles.proceedContainer}>
                <LargeButton type="submit">
                  <span>{translation.translate('purchase.cart.proceed_to_checkout')}</span>
                </LargeButton>
              </div>

            </form>
          </div>
        </div>
        <Footer></Footer>
      </div>
      <Navbar></Navbar>
    </TranslationProvider>
  );
}