import React, { useState } from 'react';
import * as styles from './duration-select.module.scss';

type Option = {
  name: string;
  value: any;
};

type DurationSelectProps = {
  options: Option[];
  value: any;
  onChange: (value: any) => void;
};

export function DurationSelect({ options, value, onChange }: DurationSelectProps) {
  return (
    <div className={styles.wrapper}>
      {options.map(option => (
        <button 
          key={option.value}
          type="button" 
          className={`${styles.option} ${value === option.value ? 'selected' : ''}`}
          onClick={() => onChange(option.value)}>
          {option.name}
        </button>
      ))}
    </div>
  );
}