// extracted by mini-css-extract-plugin
export var scrollable = "cart-module--scrollable--Lpny8";
export var wrapper = "cart-module--wrapper--1eD69";
export var heading = "cart-module--heading--3J39_";
export var text = "cart-module--text--3SeFQ";
export var amountInput__wrapper = "cart-module--amount-input__wrapper--cmI2T";
export var divider = "cart-module--divider--1FFKD";
export var total = "cart-module--total--1tp03";
export var total__heading = "cart-module--total__heading--2GA0W";
export var total__value = "cart-module--total__value--3xpzL";
export var proceedContainer = "cart-module--proceed-container--2MG7b";