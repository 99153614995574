import { navigate } from 'gatsby';
import { useEffect } from 'react';
import useAuthStatus from './auth-status.hook';

export default function useAuthGuard(navigateTo: string) {
  const {isAuthenticated, hasLoadedAuth} = useAuthStatus();
  
  useEffect(() => {
    if(hasLoadedAuth) {
      if(!isAuthenticated) {
        navigate(navigateTo);
      }
    }
  }, [isAuthenticated, hasLoadedAuth]);
}