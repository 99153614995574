import React from 'react';
import * as styles from './amount-input.module.scss';

type AmountInputProps = {
  label: string;
  value: number;
  onChange: (value: number) => void;
}

export function AmountInput({ label, value, onChange }: AmountInputProps) {
  return (
    <div className={styles.container}>
      <span>{label}</span>
      <div className={styles.buttonContainer}>
        <button type="button" className={styles.button} onClick={() => value > 0 ? onChange(value - 1) : null}>
          -
        </button>
        <span>{value}</span>
        <button type="button" className={styles.button} onClick={() => onChange(value + 1)}>
          +
        </button>
      </div>
    </div>
  );
}